import moment from "moment";
import { envName } from "../../../environments/environment";
export function factoryObject(obj) {
  const values = {};
  Object.keys(obj).forEach((item) => {
    if (typeof obj[item] === "object") {
      Object.assign(values, obj[item]);
    } else if (obj[item]) {
      Object.assign(values, { [item]: obj[item] });
    }
  });
  return values;
}

function isKeyExists(obj, key) {
  if (obj[key] == undefined) {
    return false;
  } else {
    return true;
  }
}

export function renderCPF(item) {
  if (!isKeyExists(item, "hasCPF")) return `${formataCPF(item.cpf)}, `;

  if (item.hasCPF) {
    return `${formataCPF(item.cpf)}, `;
  } else {
    return "";
  }
}
export function renderCPFLabel(item) {
  if (!isKeyExists(item, "hasCPF")) return " inscrito(a) no CPF sob o nº";

  if (item.IsForeigner || item.isForeigner) {
    return " passaporte ou RNE nº";
  }

  if (item.hasCPF) {
    return " inscrito(a) no CPF sob o nº";
  } else {
    return " não inscrito(a) no CPF,";
  }
}

export const getNameCivilStateToMinuta = (
  val,
  unionRegime,
  hasUnion,
  unionDate,
  unionStableState
) => {
  let union = "";
  if (val > 4) {
    switch (unionRegime) {
      case 1:
        union = " com comunhão universal de bens";
        break;
      case 2:
        union = " com comunhão parcial de bens";
        break;
      case 3:
        union = " com separação de bens";
        break;

      default:
        if (val === 5 && hasUnion === false) {
          union = " com comunhão parcial de bens";
        } else {
          union = "";
        }
        break;
    }
  }

  switch (Number(val)) {
    case 1:
      return "solteiro(a)" + union;

    case 2:
      return "viúvo(a)" + union;

    case 3:
      return "separado(a)" + union;

    case 4:
      return "divorciado(a)" + union;

    case 5:
      if (!unionStableState) {
        return "em união estável";
      }
      return (
        getNameCivilStateToResume(unionStableState).toLocaleLowerCase() +
        " em " +
        "união estável" +
        union +
        `${
          unionDate
            ? " celebrada em " + moment(unionDate).format("DD/MM/YYYY")
            : ""
        }`
      );
    case 6:
      if (!unionDate) {
        return "casado(a)" + union;
      }
      return `casado(a)${union}${
        unionDate
          ? " celebrada em " + moment(unionDate).format("DD/MM/YYYY")
          : ""
      }`;

    default:
      return "solteiro(a)" + union;
  }
};
export const getNameCivilStateQualification = (
  val,
  unionRegime,
  hasUnion,
  unionDate,
  unionStableState
) => {
  let union = "";
  if (val > 4) {
    switch (unionRegime) {
      case 1:
        union = " com comunhão universal de bens";
        break;
      case 2:
        union = " com comunhão parcial de bens";
        break;
      case 3:
        union = " com separação de bens";
        break;

      default:
        if (val === 5 && hasUnion === false) {
          union = " com comunhão parcial de bens";
        } else {
          union = "";
        }
        break;
    }
  }

  switch (Number(val)) {
    case 1:
      return "solteiro(a)" + union;

    case 2:
      return "viúvo(a)" + union;

    case 3:
      return "separado(a)" + union;

    case 4:
      return "divorciado(a)" + union;

    case 5:
      return (
        getNameCivilStateToResume(unionStableState).toLocaleLowerCase() +
        " e mantenho " +
        "união estável" +
        union +
        `${
          unionDate
            ? " celebrada em " + moment(unionDate).format("DD/MM/YYYY")
            : ""
        }`
      );
    case 6:
      if (!unionDate) {
        return "casado(a)" + union;
      }
      return `casado(a)${union}${
        unionDate
          ? " celebrada em " + moment(unionDate).format("DD/MM/YYYY")
          : ""
      }`;

    default:
      return "solteiro(a)" + union;
  }
};

export const getNameCivilStateToResume = (val) => {
  switch (val) {
    case 1:
      return "Solteiro(a)";

    case 2:
      return "Viúvo(a)";

    case 3:
      return "Separado(a)";

    case 4:
      return "Divorciado(a)";

    case 5:
      return "União estável";

    case 6:
      return "Casado(a)";

    default:
      return "Solteiro(a)";
  }
};
export const getStringBool = (val) => {
  if (val) return "Sim";
  return "Não";
};
export const getNameCivilState = (val) => {
  switch (val) {
    case 1:
      return "SOLTEIRO(A)";

    case 2:
      return "VIÚVO(A)";

    case 3:
      return "SEPARADO(A)";

    case 4:
      return "DIVORCIADO(A)";

    case 5:
      return "EM UNIÃO ESTÁVEL";

    case 6:
      return "CASADO(A)";

    default:
      break;
  }
};
export const getRegimeLabel = (val) => {
  switch (val) {
    case 1:
      return "Comunhão universal de bens";

    case 2:
      return "Comunhão parcial de bens";

    case 3:
      return "Separação de bens";
    default:
      break;
  }
};

export const convertNumberRoman = (val) => {
  const list = {
    1: "i",
    2: "ii",
    3: "iii",
    4: "iv",
    5: "v",
    6: "vi",
    7: "vii",
    8: "viii",
    9: "ix",
    10: "x",
    11: "xi",
    12: "xii",
    13: "xiii",
    14: "xiv",
    15: "xv",
    16: "xvi",
    17: "xvii",
    18: "xviii",
    19: "xix",
    20: "xx",
  };

  return list[val];
};

export function formataCPF(cpf) {
  if (!cpf) return "";
  //retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, "");

  //realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatDateToSave(date) {
  if (!date) return "";
  return new Date(date).toISOString().replace("T00", "T10");
}

export function formatPercentage(percentage) {
  if (percentage % 1) return `${percentage.toFixed(2)}%`;
  return `${percentage}%`;
}

export function formatCurrency(val) {
  return Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(val);
}

export function hideShowPass(id) {
  const input = document.querySelector(`#${id} input`);
  const button = document.querySelector(`#${id} .hide-password`);

  if (input.getAttribute("type") === "password") {
    input.setAttribute("type", "text");
    button.classList.add("show");
  } else {
    input.setAttribute("type", "password");
    button.classList.remove("show");
  }
}

export function isMobile() {
  return window.screen.width <= 992;
  return /Mobi|Android/i.test(navigator.userAgent);
}

export function isDevMode() {
  const whiteList = ["localhost"];
  if (
    whiteList.includes(window.location.hostname) ||
    envName === "LOCAL" ||
    envName === "DEV"
  )
    return true;

  return false;
}
export function getCurrentClient() {
  const customerSessionStorage =
    window.localStorage.getItem("X-customer-referer") || "default";
  if (isDevMode()) {
    return customerSessionStorage;
  }

  if (window.location.host.includes("staging")) {
    const env = window.location.host.replace("staging.", "");
    if (env === "planejebem.app") {
      return "default";
    }
    return env.replace(".planejebem.app", "");
  }

  return window.location.host.replace(".planejebem.app", "");
}
